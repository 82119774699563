/*eslint-disable*/
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'src/store';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated = false } = useSelector((state) => state.auth);
  const { userProfile = {} } = useSelector((state) => state.user);
  // return <Navigate to="/admin/contents/new" />;
  if (isAuthenticated) {
    if (userProfile.role_id === 3) {
      return <Navigate to="/admin/users" replace />;
    }
    else {
      return <Navigate to="/home" replace />;
    }
  }
  return <>{children}</>;
};
GuestGuard.propTypes = {
  children: PropTypes.node,
};
export default GuestGuard;
