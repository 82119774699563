/*eslint-disable*/
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import LOGODEWA from 'src/icons/logoDEWA.svg';
import MenuIcon from '../../icons/Menu';
import { useSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { SOCIAL_APP_URL, TAMM_APP_URL } from 'src/config';
import { Theme } from '@mui/material';
import { ToolBarHeight } from 'src/constants';

const ASK_LLM = "Alinma Bank Chatbot";
const TAMM_LLM = "Website Q&A";
const SOCIAL_LLM = "Social Media Analysis";
interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(760));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));

  useEffect(() => {
    setActiveButton(ASK_LLM);
  }, []);

  const { onSidebarMobileOpen, ...other } = props;
  const { showSideBar } = useSelector((state) => state.chat);
  const [activeButton, setActiveButton] = useState(ASK_LLM);
  const { settings } = useSettings();

  const handleButtonClick = (buttonName: string) => {
    // setActiveButton(buttonName);
    if (buttonName === SOCIAL_LLM) {
      window.open(SOCIAL_APP_URL, '_blank');
    } else if (buttonName === TAMM_LLM) {
      window.open(TAMM_APP_URL, '_blank');
    }
  };

  return (
    <DashboardNavbarRoot
      {...other}
      sx={{
        maxWidth: showSideBar ? `calc(100% - 220px)` : '100%',
        border: '0px',
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          paddingLeft: 0,
          height: ToolBarHeight
        }}
      >
        {/* <RouterLink to="/"> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 1,
            cursor: 'pointer',
            maxWidth: 220,
            ...(smDown && { width: '100%', justifyContent: 'center' }),
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <img alt="Attachment" src={LOGODEWA} style={{ height: '80px', width: '80px' }} />
        </Box>
        {/* <RouterLink to="/">
          <Avatar className='backgroundTransparent'
            variant="square"
            sx={{
              height: 50,
              width: 85,
              mb: 0.5,
              mt: 0.5,
            }}
          >
            <img style={{ height: '50px', width: '85px' }}src={LOGODEWA} alt={'DEWA LLM'} />
          </Avatar>
        </RouterLink> */}
        <Box
          sx={{
            flexGrow: 1,
            // ml: 2,
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <SelectModel />
        </Box> */}
        {/* <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box> */}
      </Toolbar>
      <Divider />
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
